import React from 'react'
import "./About2.css"
import SnowFall from "react-snowfall"
import InfoIcon from '@material-ui/icons/Info';

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
	// Global settings:
	disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
	startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
	initClassName: 'aos-init', // class applied after initialization
	animatedClassName: 'aos-animate', // class applied on animation
	useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
	disableMutationObserver: false, // disables automatic mutations' detections (advanced)
	debounceDelay: 20, // the delay on debounce used while resizing window (advanced)
	throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
	
  
	// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
	offset: 200, // offset (in px) from the original trigger point
	delay: 20, // values from 0 to 3000, with step 50ms
	duration: 1000, // values from 0 to 3000, with step 50ms
	easing: 'ease-in-out', // default easing for AOS animations
	once: false, // whether animation should happen only once - while scrolling down
	mirror: false, // whether elements should animate out while scrolling past them
	anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });

function About2() {
    return (
        <div data-aos="zoom-in" className="about__main">
            <div>
            {/* <Links/> */}

            </div>
            <div className="about__description">
                About Us
            </div>
            <div>

            <div className="about__overview">
            <SnowFall
                // Changes the snowflake color
                color="white"
                // Applied to the canvas element
                style={{}}
                // Controls the number of snowflakes that are created (default 150)
                snowflakeCount={60}
            />

            <p data-aos="fade-up" className="about__txt">NIOCCAN Energies Limited is an Oilfield company that provides Onshore and Offshore technical services to the Oil & Gas Industry in Nigeria. NIOCCAN as an indigenous Company with a professional and excellent engineering competency, covers many areas in Well Intervention and Completion Services.
<br/>
<br/>NIOCCAN since its incorporation has expanded its capabilities to include a wide range of services, such as Electric Wireline Services (E-Line), Slickline Services, Wellhead Maintenance and Supply Services, Pressure Pumping Services, Surveys, Well Completion Services, Haulage Services, Supply of Construction materials and more.</p>

            </div>

            </div>
            
        </div>
    )
}

export default About2
